import { KernelMetadata } from './model';

export const fetchKernelMetadata = async (
  cid: string
): Promise<KernelMetadata | null> => {
  if (!cid) {
    return null;
  }
  try {
    const response = await fetch(`/api/files/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ cid }),
    });
    if (response.ok) {
      const result = await response.json();
      return result as KernelMetadata;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getImageURL = (
  cid: string,
  width?: string,
  height?: string,
  quality?: string
): string => {
  return (
    'https://' +
    process.env.NEXT_PUBLIC_GATEWAY_URL +
    '/ipfs/' +
    cid +
    (width ? `?img-width=${width}` : '') +
    (height ? `&img-height=${height}` : '') +
    (quality ? `&img-quality=${quality}` : '')
  );
};

export const getImageURLV2 = async (
  cid: string,
  width?: string,
  height?: string,
  quality?: string
): Promise<string | null> => {
  return (
    'https://' +
    process.env.NEXT_PUBLIC_GATEWAY_URL +
    '/ipfs/' +
    cid +
    (width ? `?img-width=${width}` : '') +
    (height ? `&img-height=${height}` : '') +
    (quality ? `&img-quality=${quality}` : '')
  );
};
