'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import PostHogPageView from './PostHogPageViewCapture';
import { ReactNode } from 'react';
if (typeof window !== 'undefined' && !window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'always',
    // or 'always' to create profiles for anonymous users as well
    capture_pageview: false
  });
}
export function PHProvider({
  children
}: {
  children: ReactNode;
}) {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="PostHogProvider.tsx">
      <PostHogPageView data-sentry-element="PostHogPageView" data-sentry-source-file="PostHogProvider.tsx" />
      {children}
    </PostHogProvider>;
}