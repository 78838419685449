'use client';

import React from 'react';
import { ContractResultItem } from '@/model/model';
import { BookContractIDs } from '@/components/custom/bookmark';
import { SectionFormText } from '@/components/text';
import Link from 'next/link';
export function ContractList({
  contract,
  onDelete
}: {
  contract: ContractResultItem;
  onDelete?: () => void;
}) {
  return <div className="group relative max-h-[72px] cursor-pointer" data-sentry-component="ContractList" data-sentry-source-file="contractList.tsx">
      <Link href={`/explore/contract/${contract.contractId}`} className="flex max-h-[72px] cursor-pointer items-center border-b border-shark-900 py-4" data-sentry-element="Link" data-sentry-source-file="contractList.tsx">
        <div className="truncate">
          <SectionFormText className="text-nowrap" data-sentry-element="SectionFormText" data-sentry-source-file="contractList.tsx">
            {'Contract Address: ' + contract.smartContractAddress?.toString().slice(0, 20) + '...'}
          </SectionFormText>
          <p className="text-xs/5 text-shark-400">
            {'Contract ID: ' + contract.contractId.toString()}
          </p>
        </div>
      </Link>
      <div className="absolute right-0 top-1/2 hidden -translate-y-1/2 group-hover:block">
        <BookContractIDs value={contract?.contractId.toString()} headerCopy={false} placement="top" onDelete={onDelete} data-sentry-element="BookContractIDs" data-sentry-source-file="contractList.tsx" />
      </div>
    </div>;
}