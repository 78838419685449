'use client';

import { SidebarLayout } from '@/components/custom/sidebar-layout-modified';
import { NavbarLabel } from '@/components/navbar';
import { Sidebar, SidebarBody, SidebarHeader, SidebarItem, SidebarLabel, SidebarSection, SidebarSpacer } from '@/components/sidebar';
import { Text } from '@/components/text';
import { HomeIcon, PuzzlePieceIcon, QuestionMarkCircleIcon, SparklesIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { useState, useEffect } from 'react';
import DappIcon from '@/icons/DappIcon';
import KernelIcon from '@/icons/KernelIcon';
import ContractIcon from '@/icons/Contract';
import Loading from './loading';
import { DocumentTextIcon } from '@heroicons/react/16/solid';
const navItems = [{
  label: 'Home',
  url: '/',
  active: '/',
  icon: <HomeIcon className="focus:text-shark-400" />
}, {
  label: 'Kernels',
  url: '/kernel?page=1',
  active: '/kernel',
  icon: <KernelIcon className="focus:text-shark-400" />
}, {
  label: 'Smart contracts',
  url: '/contract?page=1',
  active: '/contract',
  icon: <ContractIcon className="focus:text-shark-400" />
}, {
  label: 'dApps',
  url: '/dapp?page=1',
  active: '/dapp',
  icon: <DappIcon className="focus:text-shark-400" />
}, {
  label: 'Explore',
  url: '/explore',
  active: '/explore',
  icon: <PuzzlePieceIcon className="active:fill-red-400" />
}];
export function PlatformLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const pageFromUrl = searchParams.get('page') ? parseInt(searchParams.get('page')!, 10) : false;
  const [hydrated, setHydrated] = useState(false);
  const [width, setWidth] = useState(240);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // const [toggleSidebar, setToggleSidebar] = useState(() => {
  //   const storedToggleStatus = localStorage.getItem('toggleStatus');
  //   return storedToggleStatus ? JSON.parse(storedToggleStatus) : false;
  // });

  // useEffect(() => {
  //   localStorage.setItem('toggleStatus', JSON.stringify(toggleSidebar));
  // }, [toggleSidebar]);

  useEffect(() => {
    if (hydrated) {
      localStorage.setItem('toggleStatus', JSON.stringify(toggleSidebar));
    }
  }, [toggleSidebar, hydrated]);
  useEffect(() => {
    setHydrated(true);
    const storedToggleStatus = localStorage.getItem('toggleStatus');
    if (storedToggleStatus) {
      setToggleSidebar(JSON.parse(storedToggleStatus));
    }
  }, []);

  // if (!hydrated) {
  //   // Avoid rendering mismatched UI until hydration is complete
  //   return hydrated;
  // }
  return hydrated ? <SidebarLayout width={width} toggleSidebar={toggleSidebar} setToggleSidebar={setToggleSidebar} sidebar={<Sidebar>
          <SidebarHeader className="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-2xl bg-bluekrnl-100 py-4 pl-4 pr-4 lg:mb-2.5">
            <div className="flex cursor-pointer items-center gap-2">
              <div className="relative h-[48px] w-[48px] text-shark-400 lg:h-[48x] lg:w-[48px]">
                <Image src="/krnl.svg" alt="Platform" fill priority sizes="(max-width: 1024px) 32px, 32px" quality={50} />
              </div>
              <NavbarLabel className="flex flex-row items-baseline gap-x-2 truncate leading-none">
                <Text className="text-sm font-semibold !text-white lg:text-xl">
                  Platform
                </Text>
              </NavbarLabel>
              <button disabled className="light:text-white hidden rounded-lg border-[1px] border-[#3D3D3D] bg-backgrounddetail px-[6px] py-[4px] text-[12px] text-white dark:text-white lg:flex">
                Beta
              </button>
            </div>
          </SidebarHeader>
          <SidebarBody className="mt-24">
            <SidebarSection>
              {navItems.map(({
          label,
          url,
          active,
          icon
        }) => <SidebarItem key={label} href={url} current={active === '/' ? pathname === '/' : pathname.startsWith(active)} className="hover:shadow-shark-950">
                  {icon}
                  <SidebarLabel current={active === '/' ? pathname === '/' : pathname.startsWith(active)}>
                    {label}
                  </SidebarLabel>
                </SidebarItem>)}
            </SidebarSection>

            <SidebarSpacer />
            <SidebarSection>
              <SidebarItem href="https://discord.com/invite/byc84wU7SG" target="_blank">
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="https://docs.platform.lat" target="_blank">
                <SparklesIcon />
                <SidebarLabel>Documentation</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/registry">
                <DocumentTextIcon />
                <SidebarLabel>Registry Details</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
        </Sidebar>}>
      {children}
      {/* {hydrated ? children : <Loading />} */}
    </SidebarLayout> : <div className="flex h-screen items-center justify-center bg-black">
      <Loading />
    </div>;
}