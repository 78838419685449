'use client';

import React from 'react';
import { KernelMetadata, KernelResultItem } from '@/model/model';
import { fetchKernelMetadata, getImageURL } from '@/model/util';
import { useEffect, useMemo, useState } from 'react';
import { hexToString } from 'viem';
import { resolverTypeToDisplayName } from '@/model/enum';
import { PhotoIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import { BookkernelIDs } from '@/components/custom/bookmark';
import { SectionFormText } from '@/components/text';
import Link from 'next/link';
export function KernelList({
  kernel,
  onDelete
}: {
  kernel: KernelResultItem;
  onDelete?: () => void;
}) {
  const [metadata, setMetadata] = useState<KernelMetadata | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loadImageFailed, setLoadImageFailed] = React.useState(false);
  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const result = await fetchKernelMetadata(hexToString(kernel.metadataCid).replace(/\0+$/, '').trim());
        setMetadata(result);
      } catch (error) {
        setError('Error fetching metadat');
      }
    };
    fetchMetadata();
  }, [kernel]);
  const imageUrl = useMemo(() => {
    if (metadata && metadata.avatarUrl.length > 0) {
      const imgeUrl = getImageURL(metadata.avatarUrl || '', '52', '52');
      return imgeUrl;
    }
    return '';
  }, [metadata]);
  return <div className="group relative max-h-[72px] cursor-pointer" data-sentry-component="KernelList" data-sentry-source-file="kernelList.tsx">
      <Link className="flex max-h-[72px] cursor-pointer items-center border-b border-shark-900 py-4" href={`/explore/kernel/${kernel.kernelId}`} data-sentry-element="Link" data-sentry-source-file="kernelList.tsx">
        <div className="flex items-center gap-x-2">
          <div className="relative flex h-10 w-10 items-center justify-center overflow-hidden rounded bg-shark-600">
            {imageUrl && !loadImageFailed ? <Image src={imageUrl} alt="Selected" className="h-full w-full object-cover" onError={() => {
            setLoadImageFailed(true);
          }} width={40} height={40} /> : <PhotoIcon className="h-5 w-4 text-contentSecondary" />}
          </div>
          <div className="max-w-64 truncate">
            <SectionFormText className="text-nowrap" data-sentry-element="SectionFormText" data-sentry-source-file="kernelList.tsx">
              {`ID: ${kernel.kernelId} `}
              <span>{`(${metadata?.name ? metadata?.name : 'Name'})`}</span>
            </SectionFormText>
            <p className="text-xs/5 text-shark-400">
              {resolverTypeToDisplayName(kernel.resolverType)}
            </p>
          </div>
        </div>
      </Link>
      <div className="absolute right-0 top-1/2 hidden -translate-y-1/2 group-hover:block">
        <BookkernelIDs value={kernel.kernelId} headerCopy={false} placement="top" onDelete={onDelete} data-sentry-element="BookkernelIDs" data-sentry-source-file="kernelList.tsx" />
      </div>
    </div>;
}