import { useEffect, useMemo, useState } from 'react';
import { useReadContracts } from 'wagmi';
import { contractRegistryContractConfig } from '@/config/contract-config';
import { ContractResultItem } from '@/model/model';
import { contractMiddleWare } from '@/model/dto';
import { ContractList } from './contractList';
import { useRouter } from 'next/navigation';
import { BookMarkLoading } from '@/app/loading';
type GetContractResult = {
  error?: Error;
  result: ContractResultItem[];
  status: 'success' | 'failure';
};
const parseContractIds = (idsString?: string): number[] => {
  try {
    if (!idsString) return [];
    let ids: string[] = [];
    if (idsString.startsWith('[') && idsString.endsWith(']')) {
      ids = JSON.parse(idsString);
    } else {
      ids = idsString.split(',');
    }
    return ids.map(id => {
      const parsed = Number(id.trim());
      if (isNaN(parsed)) {
        console.warn(`Invalid contract ID found: ${id}`);
        return null;
      }
      return parsed;
    }).filter((id): id is number => id !== null);
  } catch (error) {
    console.error('Error parsing contract IDs:', error);
    return [];
  }
};
export default function BookHeaderContractIDs() {
  const [bookContractIds, setBookContractIds] = useState<number[]>([]);
  const router = useRouter();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const contractIdsString = localStorage.getItem('bookContractIDs');
      const parsedIds = parseContractIds(contractIdsString || '');
      setBookContractIds(parsedIds);
    }
  }, []);
  const {
    data: bookContractsData,
    isLoading: isLoadingContracts,
    isFetched: isFetchedContracts,
    isFetching: isFetchingContracts
  } = useReadContracts<GetContractResult[]>({
    contracts: bookContractIds.length > 0 ? bookContractIds.map(id => ({
      ...contractRegistryContractConfig,
      functionName: 'getContract',
      args: [BigInt(id)]
    })) : [],
    query: {
      enabled: bookContractIds.length > 0
    }
  });
  const smList = useMemo(() => {
    if (!bookContractsData) {
      return [];
    }
    const successfulResults = bookContractsData.filter(item => item.status === 'success').map(item => item.result);
    return contractMiddleWare(successfulResults as ContractResultItem[]);
  }, [bookContractsData]);
  const isLoading = isLoadingContracts;
  const isFetched = isFetchedContracts;
  const isFetching = isFetchingContracts;
  const handleDeleteContract = (contractId: bigint) => {
    const storedBookmarks = localStorage.getItem('bookContractIDs');
    if (storedBookmarks) {
      let bookmarksArray = JSON.parse(storedBookmarks).map(BigInt);
      bookmarksArray = bookmarksArray.filter((id: bigint) => id !== contractId);
      localStorage.setItem('bookContractIDs', JSON.stringify(bookmarksArray.map((id: bigint) => id.toString())));
      setBookContractIds(bookmarksArray);
    }
  };
  return <div data-sentry-component="BookHeaderContractIDs" data-sentry-source-file="bookContractIDs.tsx">
      {isLoading ? <BookMarkLoading /> : <div>
          {smList.length > 0 ? smList.map((item, index) => <ContractList key={index} contract={item} onDelete={() => handleDeleteContract(item.contractId)} />) : <div className="flex min-h-[288px] items-center justify-center">
              <p className="text-shark-400">No smart contracts saved</p>
            </div>}
        </div>}
    </div>;
}