import { useState, useMemo, useEffect } from 'react';
import { useReadContracts } from 'wagmi';
import { kernelRegistryContractConfig } from '@/config/contract-config';
import { kernelMiddleWare } from '@/model/dto';
import { useRouter } from 'next/navigation';
import { KernelResultItem } from '@/model/model';
import { KernelList } from './kernelList';
import { BookMarkLoading } from '@/app/loading';
type GetKernelResult = {
  error?: Error;
  result: KernelResultItem[];
  status: 'success' | 'failure';
};
const parseKernelIds = (idsString?: string): number[] => {
  try {
    if (!idsString) return [];
    let ids: string[] = [];
    if (idsString.startsWith('[') && idsString.endsWith(']')) {
      ids = JSON.parse(idsString);
    } else {
      ids = idsString.split(',');
    }
    return ids.map(id => {
      const parsed = Number(id.trim());
      if (isNaN(parsed)) {
        console.warn(`Invalid kernel ID found: ${id}`);
        return null;
      }
      return parsed;
    }).filter((id): id is number => id !== null);
  } catch (error) {
    console.error('Error parsing kernel IDs:', error);
    return [];
  }
};
export default function BookHeaderKernelIDs() {
  const [bookKernelIds, setBookKernelIds] = useState<number[]>([]);
  const router = useRouter();

  // Load bookKernelIDs from localStorage on the client side
  useEffect(() => {
    const kernelIdsString = localStorage.getItem('bookKernelIDs');
    const parsedIds = parseKernelIds(kernelIdsString || '');
    setBookKernelIds(parsedIds);
  }, []);
  // Fetch kernel details based on bookKernelIds
  const {
    data: bookKernelsData,
    isLoading: isLoadingKernels,
    isFetched: isFetchedKernels,
    isFetching: isFetchingKernels
  } = useReadContracts<GetKernelResult[]>({
    contracts: bookKernelIds.map(id => ({
      ...kernelRegistryContractConfig,
      functionName: 'getKernel',
      args: [BigInt(id)]
    })) || [],
    query: {
      enabled: !!bookKernelIds.length
    }
  });
  const kernelList = useMemo(() => {
    if (bookKernelsData) {
      const successfulResults = bookKernelsData.filter(item => item.status === 'success').map(item => item.result);
      return kernelMiddleWare(successfulResults as KernelResultItem[]);
    }
    return [];
  }, [bookKernelsData]);
  const isLoading = isLoadingKernels;
  const isFetched = isFetchedKernels;
  const isFetching = isFetchingKernels;
  const handleDeleteKernel = (kernelId: bigint) => {
    const storedBookmarks = localStorage.getItem('bookKernelIDs');
    if (storedBookmarks) {
      let bookmarksArray = JSON.parse(storedBookmarks).map(BigInt);
      bookmarksArray = bookmarksArray.filter((id: bigint) => id !== kernelId);
      localStorage.setItem('bookKernelIDs', JSON.stringify(bookmarksArray.map((id: bigint) => id.toString())));
      setBookKernelIds(bookmarksArray);
    }
  };
  return <div data-sentry-component="BookHeaderKernelIDs" data-sentry-source-file="bookKernelIDs.tsx">
      {isLoading ? <BookMarkLoading /> : <div>
          {kernelList.length > 0 ? kernelList.map((item, index) => <KernelList key={index} kernel={item} onDelete={() => handleDeleteKernel(item.kernelId)} />) : <div className="flex min-h-[288px] items-center justify-center">
              <p className="text-shark-400">No kernels saved</p>
            </div>}
        </div>}
    </div>;
}